import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import AcademyHero from '../components/Academy/AcademyHero'
import PageNav from '../components/PageNav'
import AcademyIntro from '../components/Academy/AcademyIntro'
import Workshop from '../components/Academy/Workshop'
import ScrollToNav from '../components/ScrollToNav'
// import Virtual from '../components/Academy/Virtual'
import Spacer from '../components/Spacer'

const AcademyPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    heroTitle: { heroTitle },
    heroText,
    heroImages,
    heroVideoId,
    heroVideoImage,
    whatYouGetTitle,
    whatYouGetText,
    whatYouGetTable,
    workshops,
    // virtualAcademyLabel,
    // virtualAcademyTitle,
    // virtualAcademyText,
    // virtualAcademyLinkText,
    // virtualAcademyLinkTarget,
    // virtualAcademyImage,
    pageNavigation,
  } = data.contentfulAcademy

  const events = data.events?.events
  const workshopTitles = workshops.map(workshop => ({ title: workshop.title }))
  const navData = [...workshopTitles]

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        pathname={data.site.siteMetadata.routes.academy}
      />

      <AcademyHero
        title={heroTitle}
        text={heroText}
        images={heroImages}
        videoId={heroVideoId}
        videoImage={heroVideoImage}
      />

      <AcademyIntro
        title={whatYouGetTitle}
        text={whatYouGetText}
        data={whatYouGetTable.table.tableData}
      />

      <ScrollToNav items={navData} />

      {events
        ? React.Children.toArray(
            workshops.map((workshop, workshopIndex) => {
              const workshopType = workshop.title.toLowerCase()
              const matchingEvents = events.filter(event =>
                event.name.toLowerCase().includes(workshopType)
              )
              return (
                <Workshop
                  content={workshop}
                  events={matchingEvents}
                  type={workshopType}
                />
              )
            })
          )
        : null}

      {/* <Virtual
        label={virtualAcademyLabel}
        title={virtualAcademyTitle}
        text={virtualAcademyText}
        linkText={virtualAcademyLinkText}
        linkTarget={virtualAcademyLinkTarget}
        image={virtualAcademyImage}
      /> */}

      <Spacer size={[100, 252]} />

      <PageNav
        title={pageNavigation.title}
        linkSlug={pageNavigation.pageLink.slug}
        linkText={pageNavigation.linkText}
        image={pageNavigation.image}
      />
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        routes {
          academy
        }
      }
    }
    contentfulAcademy {
      seoTitle
      seoDescription {
        seoDescription
      }
      heroTitle {
        heroTitle
      }
      heroText {
        raw
      }
      heroImages {
        gatsbyImageData
        description
      }
      heroVideoId
      heroVideoImage {
        gatsbyImageData
        description
      }
      whatYouGetTitle
      whatYouGetText {
        raw
      }
      whatYouGetTable {
        table {
          tableData
        }
      }
      workshops {
        type
        title
        description {
          raw
        }
        components {
          raw
        }
        readMoreLabel
        readLessLabel
        eventsLabel
      }
      virtualAcademyLabel
      virtualAcademyTitle
      virtualAcademyText {
        raw
      }
      virtualAcademyLinkText
      virtualAcademyLinkTarget
      virtualAcademyImage {
        gatsbyImageData
        description
      }
      pageNavigation {
        ...PageLink
      }
    }
    events {
      events {
        name
        timezone
        startTime
        endTime
        startDate
        endDate
        description
        url
        venue
      }
    }
  }
`

export default AcademyPage
