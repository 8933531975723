import styled from 'styled-components'
import { transparentize } from 'polished'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'

export const ScrollToNavMain = styled.ul`
  display: none;
  list-style: none;
  height: 8rem;
  overflow: hidden;
  border-radius: 4.1rem;
  border: 0.1rem solid ${transparentize(0.5, colors.midGrey)};

  ${mq.tabletP} {
    display: flex;
  }
`

export const ScrollToNavItem = styled.li`
  flex: 1;

  & + & {
    border-left: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
  }
`

export const ScrollToNavButton = styled.button`
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  border: none;
  background: transparent;
`
