import React from 'react'

const IconOpen = ({
  width = 10,
  height = 13,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 13"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M6 3.5h.5H6zm-.354 9.354a.5.5 0 00.708 0l3.182-3.182a.5.5 0 10-.708-.708L6 11.793 3.172 8.964a.5.5 0 10-.708.708l3.182 3.182zM3.5 1v.5V1zm2 2.5v9h1v-9h-1zm1 0c0-.497-.145-1.23-.594-1.855C5.438.995 4.663.5 3.5.5v1c.837 0 1.312.338 1.594.73.3.417.406.933.406 1.27h1zm-3-3H0v1h3.5v-1z"
      />
    </svg>
  )
}

export default IconOpen
