import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { font } from '../../styles/vars/font.style'
import { Heading3, TextBody } from '../../styles/vars/textStyles.style'
import EventImageExpert1 from './images/expert-1.jpg'
import EventImageExpert2 from './images/expert-2.jpg'
import EventImageExpert3 from './images/expert-3.jpg'
import EventImageFundamentals1 from './images/fundamentals-1.jpg'
import EventImageFundamentals2 from './images/fundamentals-2.jpg'
import EventImageFundamentals3 from './images/fundamentals-3.jpg'
import EventImagePro1 from './images/pro-1.jpg'
import EventImagePro2 from './images/pro-2.jpg'
import EventImagePro3 from './images/pro-3.jpg'

export const EventCardMain = styled.div`
  position: relative;

  & + & {
    ${clamp('margin-left', 10, 24)}
  }
`

export const EventCardWrapper = styled.div`
  ${clamp('width', 296, 438)}
  overflow: hidden;
  ${clamp('border-radius', 10, 12)}
`

export const EventCardBody = styled.div`
  display: flex;
  flex-direction: column;
  ${clamp('height', 340, 500)}
  ${clamp('padding', 22, 40)}
  background-color: ${colors.dark};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ type, index }) => {
    if (type === 'proworkshop') {
      if (index === 1) return `url(${EventImagePro1})`
      if (index === 2) return `url(${EventImagePro2})`
      if (index === 3) return `url(${EventImagePro3})`
    }

    if (type === 'expert') {
      if (index === 1) return `url(${EventImageExpert1})`
      if (index === 2) return `url(${EventImageExpert2})`
      if (index === 3) return `url(${EventImageExpert3})`
    }

    if (type === 'fundamentals') {
      if (index === 1) return `url(${EventImageFundamentals1})`
      if (index === 2) return `url(${EventImageFundamentals2})`
      if (index === 3) return `url(${EventImageFundamentals3})`
    }
  }};
`

export const EventCardDate = styled.p`
  ${clamp('font-size', 12, 14)}
  font-weight: ${font.primary.weight.medium};
  line-height: 1.25;
  text-transform: uppercase;
  color: ${colors.lightGrey};
`

export const EventCardContent = styled.div`
  margin-top: auto;
  color: ${colors.lightGrey};

  ${TextBody},
  ${Heading3} {
    /* white-space: nowrap; */
  }
`

export const EventCardCTA = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${clamp('padding-top', 18, 24)}
  ${clamp('padding-right', 22, 39)}
  ${clamp('padding-left', 22, 39)}
  ${clamp('padding-bottom', 18, 24)}
  color: ${colors.lightGrey};
  background-color: ${colors.blue};

  :before {
    content: '';
    position: absolute;
    inset: 0;
    display: block;
  }
`

export const EventCardCTAIcon = styled.div`
  ${clamp('width', 14, 17)}
`
