import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { clamp } from '../../../styles/utils/conversion.style'

export const AcademyHeroMain = styled.div`
  position: relative;
  overflow: hidden;
  color: ${colors.light};
  text-align: center;
  background-color: ${colors.blue};
`

export const AcademyHeroTitle = styled.div`
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
`

export const AcademyHeroText = styled.div`
  max-width: 54rem;
  margin-left: auto;
  margin-right: auto;
`

export const AcademyHeroVideo = styled.div`
  position: relative;
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;

  ${mq.tabletL} {
    width: 56%;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const AcademyHeroImages = styled.div`
  position: relative;
  pointer-events: none;

  ${mq.tabletL} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const AcademyHeroImage = styled.div`
  ${clamp('border-radius', 16, 24)}
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

export const AcademyHeroImageWrap = styled.div`
  position: absolute;
  display: none;

  ${mq.tabletL} {
    display: block;

    :first-child {
      top: 16%;
      left: 0;

      ${AcademyHeroImage} {
        transform: translate(-4%, 0);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        ${clamp('width', 142, 284, breakpoints.tabletL, breakpoints.contained)}
      }
    }

    :nth-child(2) {
      top: 18%;
      right: 0;

      ${AcademyHeroImage} {
        transform: translate(22%, 0);
        ${clamp('width', 220, 438, breakpoints.tabletL, breakpoints.contained)}
      }
    }

    :nth-child(3) {
      bottom: 4%;
      right: 80%;

      ${AcademyHeroImage} {
        ${clamp('width', 142, 284, breakpoints.tabletL, breakpoints.contained)}
      }
    }

    :nth-child(4) {
      bottom: 0;
      left: 80%;

      ${AcademyHeroImage} {
        transform: translate(0%, 39%);
        ${clamp('width', 142, 284, breakpoints.tabletL, breakpoints.contained)}
      }
    }
  }
`
