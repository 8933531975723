import React from 'react'
import PropTypes from 'prop-types'
import {
  AcademyHeroMain,
  AcademyHeroTitle,
  AcademyHeroText,
  AcademyHeroImages,
  AcademyHeroImageWrap,
  AcademyHeroImage,
  AcademyHeroVideo,
} from './index.style'
import Container from '../../Container'
import Spacer from '../../Spacer'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import RichText from '../../RichText'
import { Heading1 } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import VimeoPlayer from '../../VimeoPlayer'
import { GatsbyImage } from 'gatsby-plugin-image'
import { convertLineBreaksToHTML } from '../../../utils/utils'

const imageParallaxSpeeds = [7, 10, 5, 3]

const AcademyHero = ({ title, text, images, videoId, videoImage }) => (
  <ScrollSection>
    <AcademyHeroMain>
      <Container>
        <Spacer size={[151, 213]} />

        <AcademyHeroTitle>
          <Heading1 as="h2">
            <AnimateSplitText>
              {convertLineBreaksToHTML(title)}
            </AnimateSplitText>
          </Heading1>
        </AcademyHeroTitle>

        <Spacer size={[29, 32]} />

        <AcademyHeroText>
          <RichText content={text} paragraphSize="regular" delay={0.3} />
        </AcademyHeroText>

        <Spacer size={72} />

        <AcademyHeroVideo>
          <AnimateSlideIn delay={0.5}>
            <VimeoPlayer
              id={videoId}
              thumbnail={videoImage}
              autoplay
              loop
              muted
              controls={false}
            />
          </AnimateSlideIn>
        </AcademyHeroVideo>

        <Spacer size={[82, 132]} />

        <AcademyHeroImages>
          {React.Children.toArray(
            images.map((image, imageIndex) => (
              <AcademyHeroImageWrap
                data-scroll
                data-scroll-position="top"
                data-scroll-speed={imageParallaxSpeeds[imageIndex]}
                data-scroll-delay={0.05 + imageIndex * (0.03 / images.length)}
              >
                <AnimateSlideIn delay={imageIndex * 0.075}>
                  <AcademyHeroImage>
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.description}
                    />
                  </AcademyHeroImage>
                </AnimateSlideIn>
              </AcademyHeroImageWrap>
            ))
          )}
        </AcademyHeroImages>
      </Container>
    </AcademyHeroMain>
  </ScrollSection>
)

AcademyHero.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  videoId: PropTypes.string,
  videoImage: PropTypes.object,
}

export default AcademyHero
