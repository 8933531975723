import React from 'react'
import PropTypes from 'prop-types'
import {
  ScrollToNavMain,
  ScrollToNavItem,
  ScrollToNavButton,
} from './index.style'
import ScrollSection from '../ScrollSection'
import Container from '../Container'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import { TitleSmall } from '../TextStyles'
import { scrollTo, stringToId } from '../../utils/utils'

const ScrollToNav = ({ items, offset }) => (
  <ScrollSection>
    <Container allowOverflow>
      <AnimateSlideIn>
        <ScrollToNavMain>
          {React.Children.toArray(
            items.map(item => (
              <ScrollToNavItem>
                <ScrollToNavButton
                  onClick={() => {
                    scrollTo(stringToId(item.title), 1000, offset)
                  }}
                >
                  <TitleSmall>{item.title}</TitleSmall>
                </ScrollToNavButton>
              </ScrollToNavItem>
            ))
          )}
        </ScrollToNavMain>
      </AnimateSlideIn>
    </Container>
  </ScrollSection>
)

ScrollToNav.propTypes = {
  items: PropTypes.array,
  offset: PropTypes.number,
}

export default ScrollToNav
