import React from 'react'

const IconLink = ({
  width = 19,
  height = 11,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 11"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M18.5 1a.5.5 0 00-.5-.5h-4.5a.5.5 0 000 1h4v4a.5.5 0 001 0V1zM1 9.5a.5.5 0 000 1v-1zM17.646.646l-7.5 7.5.708.708 7.5-7.5-.708-.708zm-7.5 7.5a7.76 7.76 0 01-1.063.877 4.03 4.03 0 01-.625.355c-.201.087-.354.122-.458.122v1c.296 0 .593-.09.858-.206.27-.117.537-.275.784-.442.494-.334.943-.73 1.212-.998l-.708-.708zM8 9.5H1v1H8v-1z"
      />
    </svg>
  )
}

export default IconLink
