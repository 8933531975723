import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { colors } from '../../../styles/vars/colors.style'
import { easings } from '../../../styles/vars/easings.style'

export const WorkshopContainer = styled.div`
  overflow: hidden;
`

export const WorkshopContent = styled.div`
  position: relative;
  height: ${props => `${props.defaultHeight / 10}rem`};
  overflow: hidden;

  :after {
    content: '';
    position: absolute;
    bottom: -0.1rem;
    right: 0;
    left: 0;
    display: block;
    height: 6.2rem;
    pointer-events: none;
    background: linear-gradient(
      to top,
      ${colors.lightGrey},
      ${transparentize(1, colors.lightGrey)}
    );
    opacity: ${props => (props.open ? 0 : 1)};
    transition: opacity 0.15s ease;
  }

  ul {
    margin-top: 0.5em;
  }
`

export const WorkshopToggleCTA = styled.div``

export const WorkshopToggle = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${clamp('padding-top', 21, 26)}
  ${clamp('padding-bottom', 21, 26)}
  cursor: pointer;
  color: ${colors.blue};
  border: none;
  border-bottom: 0.1rem solid ${transparentize(0.5, colors.midGrey)};
`

export const WorkshopToggleIcon = styled.div`
  transform: rotate(${props => (props.open ? '180deg' : '0deg')});
  transition: transform 0.3s ${easings.inOut.default};
`

export const WorkshopEventsWrapper = styled.div``

export const WorkshopEvents = styled.div`
  display: flex;
  width: fit-content;
  cursor: none;
`
