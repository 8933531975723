import React from 'react'
import PropTypes from 'prop-types'
import ScrollSection from '../../ScrollSection'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import { Heading2 } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import RichText from '../../RichText'
import Container from '../../Container'
import Table from '../../Table'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import { colors } from '../../../styles/vars/colors.style'
import Spacer from '../../Spacer'

const AcademyIntro = ({ title, text, data }) => (
  <ScrollSection>
    <Container>
      <Spacer size={[74, 228]} />

      <Grid>
        <GridItem tabletL={5} tabletLStart={2} desk={3} deskStart={2}>
          <Heading2 as="h3" color={colors.blue}>
            <AnimateSplitText>{title}</AnimateSplitText>
          </Heading2>
        </GridItem>

        <GridItem tabletL={5} tabletLStart={7}>
          <RichText content={text} paragraphSize="regular" />
        </GridItem>

        <GridItem desk={10} deskStart={2}>
          <Spacer size={[74, 80]} />
          <AnimateSlideIn>
            <Table data={data} multiProduct />
          </AnimateSlideIn>
        </GridItem>
      </Grid>

      <Spacer size={[88, 240]} />
    </Container>
  </ScrollSection>
)

AcademyIntro.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  data: PropTypes.array.isRequired,
}

export default AcademyIntro
