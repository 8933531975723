import React, { useContext, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gsap, Draggable, InertiaPlugin } from 'gsap/all'
import { StoreDispatch } from '../../../Store'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import { Heading1, TextBody, Title } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import ScrollSection from '../../ScrollSection'
import { colors } from '../../../styles/vars/colors.style'
import RichText from '../../RichText'
import { scrollTo, stringToId } from '../../../utils/utils'
import {
  WorkshopContainer,
  WorkshopContent,
  WorkshopEvents,
  WorkshopEventsWrapper,
  WorkshopToggle,
  WorkshopToggleCTA,
  WorkshopToggleIcon,
} from './index.style'
import IconOpen from '../../svgs/IconOpen'
import EventCard from '../../EventCard'
import AnimateSlideIn from '../../animation/AnimateSlideIn'

const Workshop = ({ content, events, type }) => {
  const dispatch = useContext(StoreDispatch)
  const eventsWrapperRef = useRef()
  const eventsRef = useRef()
  const contentRef = useRef()
  const [open, setOpen] = useState(false)
  const defaultHeight = 140

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin)

    eventsRef.current &&
      Draggable.create(eventsRef.current, {
        type: 'x',
        edgeResistance: 0.9,
        inertia: true,
      })

    const applyDraggableBounds = () => {
      eventsRef.current &&
        Draggable.get(eventsRef.current).applyBounds({
          minX: -Math.max(
            0,
            -(
              eventsWrapperRef.current.offsetWidth -
              eventsRef.current.offsetWidth
            )
          ),
          maxX: 0,
        })
    }

    applyDraggableBounds()

    window.addEventListener('resize', applyDraggableBounds)

    return () => {
      window.removeEventListener('resize', applyDraggableBounds)
    }
  }, [])

  const toggleContent = () => {
    if (!open) {
      contentRef.current.style.height = 'auto'
      const contentHeight = contentRef.current.offsetHeight
      contentRef.current.style.height = `${defaultHeight}px`

      gsap.to(contentRef.current, {
        height: contentHeight,
        duration: 0.3,
        ease: 'power3.inOut',
        onStart: () => setOpen(true),
        onComplete: () => {
          window.scroll.update()
        },
      })
    }

    if (open) {
      gsap.to(contentRef.current, {
        height: defaultHeight,
        duration: 0.3,
        ease: 'power3.inOut',
        onStart: () => {
          setOpen(false)
          scrollTo(stringToId(content.title), 300)
        },
        onComplete: () => {
          window.scroll.update()
        },
      })
    }
  }

  return (
    <ScrollSection id={stringToId(content.title)}>
      <WorkshopContainer>
        <Container allowOverflow>
          <Spacer size={[88, 224]} />

          <>
            <Grid>
              <GridItem desk={10} deskStart={2}>
                <Title as="h2">
                  <AnimateSplitText>{content.type}</AnimateSplitText>
                </Title>

                <Spacer size={4} />

                <Heading1 color={colors.blue}>
                  <AnimateSplitText>{content.title}</AnimateSplitText>
                </Heading1>
              </GridItem>
            </Grid>

            <Spacer size={[26, 54]} />

            <WorkshopContent
              ref={contentRef}
              open={open}
              defaultHeight={defaultHeight}
            >
              <Grid>
                <GridItem desk={5} deskStart={2}>
                  <RichText
                    content={content.description}
                    paragraphSize="regular"
                  />
                </GridItem>

                <GridItem desk={4} deskStart={8}>
                  <RichText
                    content={content.components}
                    paragraphSize="regular"
                  />
                </GridItem>
              </Grid>
            </WorkshopContent>

            <WorkshopToggleCTA>
              <Grid>
                <GridItem desk={10} deskStart={2}>
                  <AnimateSlideIn delay={1.2}>
                    <WorkshopToggle onClick={toggleContent}>
                      <TextBody as="span">
                        {open ? content.readLessLabel : content.readMoreLabel}
                      </TextBody>

                      <WorkshopToggleIcon open={open}>
                        <IconOpen />
                      </WorkshopToggleIcon>
                    </WorkshopToggle>
                  </AnimateSlideIn>
                </GridItem>
              </Grid>
            </WorkshopToggleCTA>
          </>

          {events.length > 0 && (
            <Grid>
              <GridItem desk={10} deskStart={2}>
                <Spacer size={[50, 114]} />

                <WorkshopEventsWrapper ref={eventsWrapperRef}>
                  <Title as="h3">
                    <AnimateSplitText>{content.eventsLabel}</AnimateSplitText>
                  </Title>

                  <Spacer size={[20, 35]} />

                  <WorkshopEvents
                    ref={eventsRef}
                    onMouseEnter={() => {
                      if (events.length > 2) {
                        dispatch({ type: 'SHOW_CURSOR' })
                      }
                    }}
                    onMouseLeave={() => {
                      if (events.length > 2) {
                        dispatch({ type: 'HIDE_CURSOR' })
                      }
                    }}
                  >
                    {React.Children.toArray(
                      events.map((event, eventIndex) => (
                        <EventCard
                          event={event}
                          type={type}
                          index={(eventIndex % 3) + 1}
                        />
                      ))
                    )}
                  </WorkshopEvents>
                </WorkshopEventsWrapper>
              </GridItem>
            </Grid>
          )}
        </Container>
      </WorkshopContainer>
    </ScrollSection>
  )
}

Workshop.propTypes = {
  content: PropTypes.object,
  events: PropTypes.array,
  type: PropTypes.string,
}

export default Workshop
