import React from 'react'
import PropTypes from 'prop-types'
import {
  EventCardBody,
  EventCardContent,
  EventCardCTA,
  EventCardCTAIcon,
  EventCardDate,
  EventCardMain,
  EventCardWrapper,
} from './index.style'
import { Heading3, TextBody, TextBodySmall } from '../TextStyles'
import Spacer from '../Spacer'
import IconLink from '../svgs/IconLink'
import AnimateSlideIn from '../animation/AnimateSlideIn'

const EventCard = ({ delay, event, type, index }) => (
  <EventCardMain>
    <AnimateSlideIn delay={delay}>
      <EventCardWrapper>
        <EventCardBody type={type} index={index}>
          <EventCardDate>
            {event.startTime}-{event.endTime},{' '}
            {event.startDate === event.endDate
              ? event.startDate
              : event.startDate.substr(0, event.startDate.indexOf(' ')) +
                '-' +
                event.endDate}{' '}
            ({event.timezone})
          </EventCardDate>

          <EventCardContent>
            <TextBody>{event.venue}</TextBody>
            <Heading3 as="h4">{event.name}</Heading3>
            <Spacer size={16} />
            <TextBodySmall>{event.description}</TextBodySmall>
          </EventCardContent>
        </EventCardBody>

        <EventCardCTA href={event.url} target={'_blank'} rel={'norefferer'}>
          <TextBody>Book Now</TextBody>

          <EventCardCTAIcon>
            <IconLink responsive />
          </EventCardCTAIcon>
        </EventCardCTA>
      </EventCardWrapper>
    </AnimateSlideIn>
  </EventCardMain>
)

EventCard.propTypes = {
  delay: PropTypes.number,
  index: PropTypes.number,
  event: PropTypes.object,
  type: PropTypes.string,
}

export default EventCard
